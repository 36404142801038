<template>
  <div class="home">
    <div class="header">
      <img class="col" src="@/assets/logo.png"/>
    </div>
    <div class="main">
      <img class="bg" src="@/assets/bg1.png"/>
    </div>
    <div class="footbar">
      <div>Game Wonder Limited</div>
      <div>ROOM 511, 5/F, MING SANG IND BLDG, 19-21 HING YIP STREET, KWUN TONG, KLN HONG KONG</div>
      <div>admin@gamewonder.vip</div>
    </div>
    <div class="privacy">
      <div>
        <a style="color:#f2de00;" @click="onClickTS" >Terms of Use</a>
        <span style="color:#f2de00">  |  </span> 
        <a style="color:#f2de00;" @click="onClickP" >Privacy Policy</a>
      </div>
    </div>
    <div class="copyright">
      <div>Copyright ©Game Wonder 2023</div>
    </div>
  </div>
</template>

<script>
export default {
  title: "Game Wonder",
  name: 'HomeView',
  data: function(){
    return {
    }
  },
  props: {
  },
  mounted: function(){
    document.getElementById("body").style.backgroundColor = "#2c1f4d";
  },
  beforeDestroy: function(){
    document.getElementById("body").style.backgroundColor = "";
  },
  methods: {
    onClickTS: function(){
      this.$router.push("TermsOfUse")
    },
    onClickP: function(){
      this.$router.push("Privacy")
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  background-color: #2c1f4d;
  padding: 0px 0px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: inline-block;
    height: 67%;
    margin: 15px 15px;
  }
  div {
    text-align: center;
    color: white;
    font-size: 17px;
  }
}
.main {
  background-color: #050a21;
  height: 60vw/1920*1080;
  img {
    display: inline-block;
    width: 60%;
  }
}

@media screen and (min-width: 700px) {
  .main {
    height: 60vw/1920*1080;
    img {
      width: 60%;
    }
  }
}

@media screen and (max-width: 700px) {
  .main {
    height: 100vw/1920*1080;
    img {
      width: 100%;
    }
  }
}

.footbar {
  height: 120px;
  background-color: #2c1f4d;

  div {
    text-align: center;
    margin-left: 36px;
    color: #b476d8;
    padding: 8.5px;
    font-size: 9px;
  }

  :nth-child(1) {
    padding-top: 29px;
  }
}

.privacy {
  height: 60px;
  background-color: #1f153a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}

.copyright {
  color: #b476d8;
  font-size: 11px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c1f4d;
}
</style>
